<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">{{ lista_campos != '' ? 'Editar' : 'Criar' }} Regra de permissão</h3>
        </div>
        <div class="card-body">
          <form action="">
            <div class="form-group row">
              <div class="col-md-8">
                <label class="col-md-12">Nome:*</label>
                <input
                    required
                    type="text"
                    class="form-control"
                    v-model="form.name"
                    placeholder="Digite o nome..."
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-md-12">Ações:*</label>
                <treeselect
                    :multiple="true"
                    :options="permissions_mapped_list"
                    placeholder="Selecione as permissões..."
                    v-model="form.permissions"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                    @click.prevent="confirm(lista_campos == '' ? 'cria' : 'edita')"
                    class="btn btn-primary"
                    :disabled="verif"
                >
                  Salvar
                  <b-spinner
                      v-show="verif"
                      small
                      variant="dark"
                      type="grow"
                      label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {fireAlert} from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      form: {
        name: "",
        permissions: [],
      },
      verif: false,
    };
  },
  created() {
    this.preenxerCampos();
  },
  computed: {
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert;
    },
    permissions_mapped_list() {
      let permissions = Object.values(this.$store.state.sistema.lista_permissoes_filial)

      return permissions.reduce((data, current) => {
        let key = current.split('_').pop()
        if (data.indexOf(key) === -1) {
          data.push(key)
        }
        return data
      }, []).map(group => {
        return {
          id: group,
          label: group.replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2"),
          children: permissions
              .filter(perm => perm.split('_').pop() === group)
              .map(perm => ({id: perm, label: perm}))
        }
      })
    }
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` un Perfil no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      await this.$store.dispatch("permissoes/create_role", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert, routeName: 'permissoes'
      });
    },
    async update() {
      this.verif = true;
      await this.$store.dispatch("permissoes/update_role", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert, routeName: 'permissoes'
      });
    },
    async preenxerCampos() {
      if (this.lista_campos != '') {
        this.verif = true;
        await this.$store.dispatch("permissoes/read_role", this.lista_campos.id);
        let perm = this.$store.state.permissoes.role.permissions.reduce((data, current) => {
          if (data.indexOf(current.name) === -1) {
            data.push(current.name)
          }
          return data
        }, [])

        perm.forEach(i => {
          let alias = i.split('_').pop()
          if (perm.filter(perm => perm.split('_').pop() === alias).length >= 8) {
            perm.indexOf(alias) === -1 && perm.push(alias)
          }
        })

        this.form = {
          id: this.lista_campos.id,
          name: this.lista_campos.name,
          permissions: perm
        };
        this.verif = false;
      }
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>